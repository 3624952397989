import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import "./addmn.css";


import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function EditBank() {
	const [profile, setProfile] = useState(null);

	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const [bank, setBank] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		axios
            .get(`https://server.vascara-group.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
		axios
			.get(`https://server.vascara-group.com/bank/user/${id}`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);
	const onSubmit = (data) => {
		const formData = {
			id: id,
			name_bank: data.name_bank,
			stk: data.stk,
			fullname: data.fullname,
		};
		axios
			.post(`https://server.vascara-group.com/bank/updateBank`, formData)
			.then((res) => {
				swal({
					title: "Thành công",
					text: "Sửa ngân hàng thành công",
					icon: "success",
					buttons: "Đồng ý",
				}).then(() => navigate("/addbank"));
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: "Lỗi giao dịch 404!",
				})
			);
	};
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Thêm tài khoản ngân hàng
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div className="content_profile">
					{bank != null ? (
						<>
							<form
								style={{ display: "block", padding: "15px" }}
								onSubmit={handleSubmit(onSubmit)}
							>
								<div>
									<div>
										<label
											style={{
												marginTop: "15px",
												marginBottom: "0px",
												display: "block",
											}}
										>
											Tên ngân hàng
										</label>
										<input
											className="ipadd"
											type="text"
											{...register("name_bank", { required: true })}
											placeholder="Tên ngân hàng"
											defaultValue={bank.name_bank}
										/>
									</div>
									<div>
										{" "}
										<label
											style={{
												marginTop: "15px",
												marginBottom: "0px",
												display: "block",
											}}
										>
											Số tài khoản
										</label>
										<input
											className="ipadd"
											type="number"
											{...register("stk", { required: true })}
											placeholder="Số tài khoản"
											defaultValue={bank.stk}
										/>
									</div>
									<div>
										{" "}
										<label
											style={{
												marginTop: "15px",
												marginBottom: "0px",
												display: "block",
											}}
										>
											Chủ tài khoản
										</label>
										<input
											className="ipadd"
											type="text"
											{...register("fullname", { required: true })}
											placeholder="Chủ tài khoản"
											defaultValue={bank.fullname}
										/>
									</div>
									<button type="submit" className="btn-submit">
										Xác nhận
									</button>
								</div>
							</form>
						</>
					) : (
						<div>Đang load dữ liệu</div>
					)}
				</div>

				<Footer profile={profile}/>
			</div>
		</>
	);
}
export default EditBank;
